import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  InputBase,
  MenuItem,
  Hidden,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ClickAwayListener,
  Divider,
  Checkbox

} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import {
  searchIcon,
  dropDownSelectIcon,
  emptyImage
} from "../assets/assets";
import {
  backIconMobile
} from "./assets";
import { withRouter } from "react-router-dom";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { StyledTextField } from "./Suppliers.web";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { withSnackbar } from "../../../components/src/toastweb";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {getDividerTextStyleCustomer,  getStatusTextStyleCustomer} from "./utility.web"
import { StyledGrid } from "../../RequestManagement/src/Requests.web"
import EmployeesController, {
  Customer,
  Props,
  MocktableRow,
} from "./EmployeesController.web";
// Customizable Area End



// Customizable Area Start
const StyledTableRow = withStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: CONSTANTS.color.blueViolet,
      "& .MuiInputBase-root .MuiInputBase-input": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      }
    }
  }
})(TableRow);
enum CSSClass {
  marginTopHeadings = "marginTopHeadings",
  tableCellLayout = "tableCellLayout",
  padding_16 = "padding_16",
  customersHeadLayoutMargin = "customersHeadLayoutMargin",
  menuItemStyle = "menuItemStyle",
  CustboxContainer = "CustboxContainer",
  CustbuttonContainer = "CustbuttonContainer",
  CustbuttonText = "CustbuttonText",
  CustdropDownSelectIcon = "CustdropDownSelectIcon",
  CusttableHeadText = "CusttableHeadText",
  CusttableCell = "CusttableCell",
  CustmenuItem = "CustmenuItem",
  customerBackIconContainer = "customerBackIconContainer"
}
interface ComponentClasses {
  [key: string]: CSSClass;
}
interface ToggleData {
  id: number;
  title: string;
  hidden: boolean;
}

type EmployeeAttributes = {
  full_name: string;
  phone_number: string;
  email: string;
  default_billing_rate: string;
  employee_image: { url: string } | null;
};

type Employee = {
  id: string;
  type: string;
  attributes: EmployeeAttributes;
};

type EmployeeDataSection = {
  data: Employee[];
};

type EmployeesData = {
  active: EmployeeDataSection;
  archived: EmployeeDataSection;
};
// Customizable Area End

export class Employees extends EmployeesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  statusModalEmployees = (classes: ClassNameMap) => {
    const { t } = this.props;
    const mockStatusDataEmp = [
      { id: 1, title: t(`customers.mockStatus.${0}.title`),statusName:"all"},
      { id: 2, title: t(`customers.mockStatus.${1}.title`) ,statusName:"active"},
      { id: 3, title: t(`customers.mockStatus.${2}.title`),statusName:"archived" },
    ]
    return (
      <ClickAwayListener onClickAway={this.handleStatusModalClose}>
        <Typography>
          <Box style={{...webStyle.statusContainer, ...webStyle.positionStyle}}>
            <Box style={webStyle.statusModal}>
              {mockStatusDataEmp.map((item) => (
               
               <Box key={item.id} display={"flex"} mb={1} gridGap={"0.5rem"}>
               <Checkbox
                 data-test-id="handleSelectStatus"
                 checked={this.state.checkedItems.some((subItem) => item.statusName === subItem)}
                 onChange={this.handleSelectStatus.bind(this,item,mockStatusDataEmp)}
                 style={webStyle.checkBoxStyle} />
               <Typography className={classes.menuItemStyle} variant="body1">
                 {item.title}
               </Typography>
             </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  toggleModalEmployees = (classes: ClassNameMap) => {
    return (
      <ClickAwayListener onClickAway={this.handleToggleModalClose}>
        <Typography>
          <Box style={{...webStyle.toggleContainer, ...webStyle.positionStyle}} className={classes.toggleContainer}>
            <Box style={{...webStyle.toggleModal, ...webStyle.flexDirectionStyle}}>
              {this.state.toggleDataEmployees.map((item: ToggleData) => (
                <Box style={webStyle.toggleIconsContainer} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumn"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={this.state.checkedColumn.some((subItem) => item.id === subItem)}
                    onChange={this.handleToggleEmployees.bind(this,item)}
                  />
                  <MenuItem
                    style={webStyle.menuItemFields}
                    className={classes.menuItemFields}
                    value={item.title}
                    disableRipple
                  >
                    {item.title}
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  tableBodyData = (classes: ClassNameMap,data:Employee[]) => {
    const { t } = this.props;
    const mockStatus = ["all", "active", "archived"];
    const mockTableRowEmp = [
      { id: 1, key: "full_name", title: t('boEmployees.employeeName') },
      { id: 2, key: "phone_number", title: t('boEmployees.phoneNumber') },
      { id: 3, key: "email", title: t('boEmployees.emailAddress') },
      { id: 4, key: "default_billing_rate", title: t('boEmployees.defaultBillingRate') },
    ];

    return (
      <>
        {mockStatus.map((status: string) => {
          return (
            <>
              {Object.keys(this.state?.employeesData)?.includes(status) &&
                <>
                  <Grid className={classes.padding_16} container>
                    <Typography variant="body2"
                      style={getStatusTextStyleCustomer(status)}
                    >
                      <Grid style={webStyle.statusActive}>
                      {status === "active" ?
                        this.getActiveLangStatus(this.state.languageSelected, t, status)

                        : this.getArchivedLangStatus(this.state.languageSelected, t, status)}
                        </Grid>
                    </Typography>
                  </Grid>
                  <Divider style={getDividerTextStyleCustomer(status)} className={classes.marginTopHeadings} />
                </>
              }
              {(this.state?.employeesData?.[status]?.data?.length === 0 || this.state?.employeesData?.[status]?.length === 0) &&
                <Grid container justifyContent="center"
                  alignItems="center"
                  style={webStyle.containerHeight}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle1"
                      style={
                        {
                          ...webStyle.CustnoSupplierText,

                        }}
                      align="center">{t('boEmployees.noDataFound')}.</Typography>
                  </Grid>
                </Grid>
              }
              <Box style={webStyle.boxStyle}>
                {this.state?.employeesData?.[status]?.data?.map((item: Customer) => {

                  return (
                    <Grid container style={webStyle.gridEmployee}>
                      <Grid item xs={12}
                        key={item.id} onClick={() => this.handleViewEmployee(item)}
                      >
                        <StyledGrid container
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexWrap: 'nowrap',
                            gridAutoFlow: 'column',
                            zIndex: 0,
                            position: 'relative',
                            color: "#626262"

                          }}
                          data-test-id="moveHover"
                          key={item.id}
                        >
                          {mockTableRowEmp.map((subItem: MocktableRow) => {
                            const isIdChecked = this.state.checkedColumn.includes(subItem.id);
                            return (

                              isIdChecked &&
                              <Grid item data-test-id="viewData"
                                style={{ width: `${(100 / this.state.checkedColumn.length)}%`, minWidth: "185px" }}
                                className={classes.tableCellLayout}
                              >
                                {this.renderTypo(item, subItem, classes)}
                              </Grid>
                            )
                          })}
                        </StyledGrid>
                        <Divider />
                      </Grid>
                    </Grid>
                  )
                })}
              </Box>
            </>
          )
        })}
      </>
    )
  }
  renderTypo = (rowItem:any , subItem: MocktableRow, classes: ClassNameMap) => {
    let valueToShow = rowItem.attributes[subItem.key];
    return (  
      <Typography variant="body1" className={classes.textTableCell}>
        {valueToShow}
      </Typography>
     
    );
  }
  getActiveLangStatus = (lang: string, t: (key: string) => string, status: string) => {
    return lang === "en" ? status : t(`customers.statuses.${0}`)
  }
  getArchivedLangStatus = (lang: string, t: (key: string) => string, status: string) => {
    return lang === "en" ? status : t(`customers.statuses.${1}`)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { t } = this.props;
    const mockTableRowEmp = [
      { id: 1, key: "full_name", title: t('boEmployees.employeeName') },
      { id: 2, key: "phone_number", title: t('boEmployees.phoneNumber')  },
      { id: 3, key: "email", title: t('boEmployees.emailAddress') },
      { id: 4, key: "default_billing_rate", title: t('boEmployees.defaultBillingRate') },
    ]
    return (
      <>
        <DashboardSideBar permissions={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.employee')} active={false} active1={2} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.boxContainerEmployees}
            className={classes.boxContainerEmployees}
          >
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item lg={7} md={6} xs={12}>
                  <Box style={webStyle.backIconContainer}>
                    <Hidden smUp>
                      <img
                        style={this.state.languageSelected === "ar" ? {...webStyle.backIconMobile, transform: "rotate(180deg)"} : webStyle.backIconMobile}
                        src={backIconMobile}
                        onClick={this.handleGoBackIcon}
                        alt=""
                      />
                    </Hidden>
                    <Box style={webStyle.container}>
                      <Box>
                        <img className={classes.searchIcon} style={webStyle.searchIcon} src={searchIcon} alt="" />
                      </Box>
                      <InputBase
                        data-test-id="handleChangeSearchEmployee"
                        style={webStyle.inputBaseField}
                        className={classes.inputBaseField}
                        placeholder={t('boEmployees.searchEmployee')}
                        inputProps={{ 'aria-label': 'search' }}
                        value={this.state.searchEmployee}
                        onChange={this.handleChangeSearchEmployee}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={6} xs={4}>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    className={this.state.languageSelected === 'ar' ? 'language-ar' : ''}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.statusModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      )
                    }}
                    value={"Status"}
                    onClick={this.handleStatusModalOpen}
                  >
                  </StyledTextField>
                  {this.state.statusModal ? (
                    this.statusModalEmployees(classes)
                  ) : null}
                </Grid>
                <Grid item lg={3} md={6} xs={8}>
                  <StyledTextField
                    fullWidth
                    defaultValue={t('boEmployees.toggleColumns')}
                    variant="outlined"
                    className={this.state.languageSelected === 'ar' ? 'language-ar' : ''}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.toggleModal ? webStyle.dropDownSelectIcon : {...webStyle.dropDownSelectIcon, transform: "rotate(180deg)"}} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleToggleModalOpen}
                  >
                  </StyledTextField>
                  {this.state.toggleModal ? (
                    this.toggleModalEmployees(classes)
                  ) : null}
                </Grid>
              </Grid>
               <Box>
                <Grid style={webStyle.CustsuppliersContainer}>
                  <Grid item xs={12} 
                    style={{ 
                      overflow: 'scroll', 
                      minWidth: "fit-content" 
                    }}
                  >
                    <Grid container
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'nowrap',
                      }}
                    >
                      {mockTableRowEmp.map((rowItem) => {
                         const isIdChecked = this.state.checkedColumn.includes(rowItem.id);
                        return (
                          <>
                            {isIdChecked  &&
                              <Grid item
                                className={classes.padding_16}
                                key={rowItem.id} data-test-id="handleSort" 
                                onClick={() => this.handleSortEmployees(rowItem.key)}
                                style={
                                  this.state.sortColumn === rowItem.key
                                    ? {
                                      width:`${(100 / mockTableRowEmp.length)}%`
                                    }
                                    : {
                                      width: `${(100 / this.state.checkedColumn.length)}%`
                                    }
                                }
                              >
                                <Box style={webStyle.CusttableHeadSpan}>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                  >
                                    {rowItem.title}
                                  </Typography>
                                  <img 
                                    style={(this.state.sortColumn === rowItem.key) && 
                                    this.state.sortDirection === 'asc' ? 
                                    { ...webStyle.dropDownSelectIconTable, 
                                    transform: 'rotate(180deg)' } : webStyle.dropDownSelectIconTable} 
                                    src={dropDownSelectIcon} 
                                    className={classes.dropDownSelectIcon}
                                    alt="" 
                                   />
                                </Box>
                              </Grid>
                            }
                          </>
                        );
                      })}
                    </Grid>
                    <Divider className={classes.customersHeadLayoutMargin} />
                    {this.tableBodyData(classes,this.state.employeesData.active?.data)}
                  </Grid>
                </Grid>
              </Box>
              <Box style={webStyle.buttonsContainer}>
                <CreateQuote
                  onClick={this.handleCreateEmployee}
                >
                  <Typography
                    style={{...webStyle.buttonTextCreateEmployee, textAlign: "center"}}
                    className={classes.buttonTextCreateEmployee}
                    variant="subtitle2"
                  >
                    {t('boEmployees.createAnEmployee')}
                  </Typography>
                </CreateQuote>
              </Box>
            </Container>
          </Box>
        </StyledRoot>
        <LoadingBackdrop
          loading={this.state.getAllEmployeesLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  tableCellLayout:{
  
    padding: '28px 16px 28px 16px !important'
  },
  boxContainerEmployees: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.25rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important",
    },
  },
  buttonTextCreateEmployee: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  dropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    }
  },
  menuItemFields: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  inputBaseField: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  searchIcon: {
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0.6rem 0px 1.3rem !important",
    }
  },
  tableHeadSpanAr: {
    [theme.breakpoints.down("sm")]: {
      gap: "6px !important",
    }
  },
  toggleContainer: {
    [theme.breakpoints.down("xs")]: {
      right: "23px !important",
    }
  },
  menuItemStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  customersHeadLayoutMargin: {
    marginTop: '0.5rem'
  },
  padding_16: {
    padding: '16px 16px 0px 16px'
  }
});

const webStyle = {
  boxContainerEmployees: {
    marginTop: "9rem",
    paddingBottom: "5rem"
  },
  CustsuppliersContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "400x",
    overflow: 'scroll',
  },
  CusttableHeadSpan: {
    cursor: "pointer",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#626262",
  },
  statusModalStyle: {
    display: "flex",
    gap: "1rem",
    padding: "5px 0",
  },
  menuItemStyle: {
    color: CONSTANTS.color.grey,
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  checkbox: {
    padding: 0,
    color: CONSTANTS.color.darkBlue
  },
  container: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  searchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  inputBaseField: {
    width: "100%",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    color: CONSTANTS.color.grey
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer"
  },
  statusContainer: {
    zIndex: 9,
    width: "182px",
    minHeight: "102px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  statusModal: {
    padding: "1rem"
  },
  menuItemFields: {
    color: CONSTANTS.color.grey,
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  backgroundMenuItem: {
    backgroundColor: CONSTANTS.color.cement
  },
  toggleContainer: {
    padding: "1rem",
    zIndex: 9,
    width: "252px",
    minHeight: "194px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  positionStyle: {
    position: "absolute" as const,
  },
  toggleModal: {
    display: "flex",
    justifyContent: "center",
  },
  flexDirectionStyle: {
    flexDirection: "column" as const
  },
  toggleIconsContainer: {
    display: "flex",
    alignItems: "center"
  },
  toggleIcons: {
    width: "24px",
    height: "24px"
  },
  tableCell: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    width: "25%",
    maxWidth: "100%"
  },
  tableCellBody: {
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "start" as "start",
    wordBreak: "break-all" as "break-all",
  },
  cursorStyle: {
    cursor: "pointer"
  },
  profileImage: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "10px",
  },
  userIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%"
  },
  backgroundTableHead: {
    backgroundColor: "rgba(232,232,232,0.4)"
  },
  activeText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "1rem 1rem 5px 1rem"
  },
  hr: {
    display: "flex",
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
    minWidth: "710px",
  },
  tableLayoutStyle: {
    tableLayout: "fixed" as const
  },
  buttonsContainer: {
    marginTop: "3rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonTextCreateEmployee: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  dropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    marginLeft: "2rem",
    transform: "rotate(0deg)"
  },
  noEmployeesText: {
    color: CONSTANTS.color.darkGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  backIconMobile: {
    height: "24px",
    width: "12px",
  },
  backIconContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  CustnoSupplierText: {
    color: "#535353",
    fontWeight: 500,
    textAlign: "center" as const,
  },
  statusActive : {
    padding:"15px 0 10px 15px"
  },
  containerHeight : {
    height: '150px'
  },
  boxStyle : {
    maxHeight: '300px', 
    overflow: 'scroll', 
    minWidth: "fit-content"
  },
  gridEmployee : {
    maxHeight: '300px', 
    overflow: 'scroll', 
    minWidth: "fit-content"
  },
  styleGrid : {
      display: 'flex',
      width: '100%',
      flexWrap: 'nowrap',
      gridAutoFlow: 'column',
      zIndex: 0,
      position: 'relative',
      color: "#626262"
  },
  checkBoxStyle : {
     padding: 0, 
     color: "#1c18af" 
  }
}

const style: any = withStyles(styles)(Employees);

const router = withRouter(style);
export default withTranslation()(withSnackbar(router));
// Customizable Area End