import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Hidden,
  Button,
  Typography,
  Container,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  ClickAwayListener
} from "@material-ui/core";
import {
  styled,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import {
  emptyImage,
  iIcon,
  editIcon,
  afterHoverIcon,
  confirmationImage,
  selectedIcon,
  threeDotsIcon,
  dropDownSelectIcon,
  dialogBoxIcon,
  galleryIcon,
  deleteProfileIcon,
  backIconMobile
} from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { StyledCheckbox, StyledRadio } from "../../Settings/src/CompanyInformation.web";
import { StyledRoot, countryCodes } from "../../dashboard/src/utility.web";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from "../../../components/src/toastweb";
import { withRouter } from "react-router-dom";
export const configJSON = require("./config");
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ContainedButton, ArcheiveButton, CancelButton } from "../../../components/src/CustomizableComponents.web"
// Customizable Area End

import EmployeesController, {
  Props,
} from "./EmployeesController.web";

// Customizable Area Start
const StyledTextFields = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      color: CONSTANTS.color.midGrey,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important",
      }
    }
  }
}))(TextField);

export const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      color: CONSTANTS.color.midGrey,
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px !important",
      }
    }
  }
}))(TextField);

interface TextfieldProps {
  languageSelected: string;
}

export const StyledTextFieldAutoComplete = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      height: "59px",
      borderRadius: "10px",
      color: CONSTANTS.color.midGrey,
      justifyContent: (props: TextfieldProps) => props.languageSelected === "ar" ? "center" : "flex-start",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important",
      }
    },
    "& .MuiOutlinedInput-input":{
      cursor: "pointer",
      padding:"15px 12px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderColor: CONSTANTS.color.thickBlue,
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root":{
      fontSize: "2.1rem",
      marginTop: "-5px",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "95px"
    }
  }
}))(TextField);

const StyledDialog = withStyles({
  root: {
    "& .MuiPaper-root": {
      padding: "1.5rem 1rem",
      width: "100%",
      maxWidth: "657px",
      minHeight: "276px",
      borderRadius: "20px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 8px 24px rgba(0,0,0,0.1)"
    }
  }
})(Dialog);

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    padding: "15px 14px 2px",
    "& .MuiTypography-h6": {
      color: CONSTANTS.color.lightBrown,
      fontSize: "24px",
      fontWeight: CONSTANTS.fontWeight.fontWeight500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px !important"
      },
    },
  }
}))(DialogTitle);

const StyledDialogContentText = withStyles((theme: Theme) => ({
  root: {
    color: CONSTANTS.color.lightGrey,
    marginBottom: "8px",
    fontSize: "18px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px !important"
    },
  }
}))(DialogContentText);

const StyledDialogActions = withStyles({
  root: {
    justifyContent: "space-between",
  }
})(DialogActions);


const StyledDialogBilling = withStyles({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "593px",
      borderRadius: "20px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 8px 24px rgba(0,0,0,0.1)"
    }
  }
})(Dialog);

const StyledDialogDelete = withStyles({
  root: {
    "& .MuiPaper-root": {
      padding: "1rem",
      width: "100%",
      maxWidth: "634px",
      minHeight: "263px",
      borderRadius: "8px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)"
    }
  }
})(Dialog);

const StyledDialogTitleDelete = withStyles((theme) => ({
  root: {
    padding: "15px 24px 2px",
    "& .MuiTypography-h6": {
      color: CONSTANTS.color.lightBrown,
      fontSize: "1.8741rem",
      fontWeight: CONSTANTS.fontWeight.fontWeight500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6867rem !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.4375rem !important"
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 10px 2px !important",
    },
  }
}))(DialogTitle);

const StyledDialogContentTextDelete = withStyles((theme) => ({
  root: {
    color: CONSTANTS.color.lightGrey,
    marginBottom: "8px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    fontSize: "1.52rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4286rem !important"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem !important",
    },
  }
}))(DialogContentText);

const StyledDialogContent = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px 10px !important",
    },
  }
}))(DialogContent);

const StyledBoxModal = styled(Box)(({ theme, languageSelected }: { theme: Theme; languageSelected: string }) => ({
  [theme.breakpoints.down("sm")]: {
    transform: languageSelected === "ar" ? "translate(-12%, 10%) !important" : "translate(12%, 10%) !important",
  },
  [theme.breakpoints.down("xs")]: {
    maxWidth: "328px !important",
    transform: "translate(0%, 10%) !important",
  },
}));

function getSrcValue(showText: boolean, afterHoverIcon: string, iIcon: string) {
  return showText ? afterHoverIcon : iIcon;
}

const getConditionalStyle = (isError: any) => {
  return isError && webStyle.billingRateError;
};

const mobileBackIcon = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.backIconMobile, ...webStyle.transformStyle} : webStyle.backIconMobile
}
const editIconFunction = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.editIcon, ...webStyle.transformStyleEditIcon} : webStyle.editIcon
}

const styledBoxModal = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.changeLogoContainer, ...webStyle.transformStyleBoxModal} : webStyle.changeLogoContainer
}

const emailIconHover = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.hoverStyle, ...webStyle.positionStyle, ...webStyle.transformHoverStyle} : {...webStyle.hoverStyle, ...webStyle.positionStyle}
}

const billingRateStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.defaultBillingRateContainer, ...webStyle.borderRadiusBillingAr} : webStyle.defaultBillingRateContainer
}

const toolTipStyle = (languageSelected: string) => {
  return languageSelected === "ar" ? {...webStyle.plusIconContainer, borderRadius: "10px 0px 0px 10px"} : webStyle.plusIconContainer
}
// Customizable Area End

export class CreateEmployee extends EmployeesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  deleteBillingRatesDialog = (classes: ClassNameMap) => {
    const employee_id = localStorage.getItem(configJSON.employee_id);
    return (
      <StyledDialogDelete
        open={this.state.billingDeleteDialogOpen}
        onClose={this.handleCloseDeleteDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseIcon
          style={this.state.languageSelected === "ar" ? {...webStyle.closeIcon, right: "88%", left: "auto"} : webStyle.closeIcon}
          className={this.state.languageSelected === "ar" ? classes.closeIconAr : classes.closeIcon}
          onClick={this.handleCloseDeleteDialog}
        />
        <StyledDialogTitleDelete id="responsive-dialog-title">
          {this.props.t('boEmployees.deleteBillingRate')}
        </StyledDialogTitleDelete>
        <StyledDialogContent>
          <StyledDialogContentTextDelete>
            {this.props.t('boEmployees.deleteBillingRateDesc')}
          </StyledDialogContentTextDelete>
        </StyledDialogContent>
        <DialogActions style={webStyle.gapStyle}>
          <CancelButton
            style={{maxWidth: "162px"}}
            className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
            onClick={this.handleCloseDeleteDialog}
          >
            <Typography
              className={classes.buttonTextMobile}
              variant="subtitle2"
            >
              {this.props.t('boEmployees.cancel')}
            </Typography>
          </CancelButton>
          <Box
            style={{...webStyle.buttonContainerCancel, border: `1px solid ${CONSTANTS.color.darkBlue}`, maxWidth: "162px"}}
            className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile} ${classes.buttonCommon}`}
            onClick={employee_id ? this.handleDeleteBillingRateData : this.handleDeleteBillingRate}
          >
            <Typography
              style={{...webStyle.buttonTextCancel, ...webStyle.deleteButtonBillingRate}}
              className={classes.buttonTextMobile}
              variant="subtitle2"
            >
              {this.props.t('boEmployees.delete')}
              <LoadingBackdrop
                loading={this.state.deleteEmployeeBillingRatesLoading}
              />
            </Typography>
          </Box>
        </DialogActions>
      </StyledDialogDelete>
    );
  }

  addNewBillingRatesDialog = (classes: ClassNameMap) => {
    const employee_id = localStorage.getItem(configJSON.employee_id);
    const getConditionalStyle = (isError: any) => {
      return isError && webStyle.billingRateError;
    };

    const { name, hourly_rate, tax_rate } = this.state.billingFormData;
    const { t } = this.props;
    return (
      <StyledDialogBilling
        open={this.state.billingRateDialogOpen}
        onClose={this.handleCloseBillingRateDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid container>
          <Grid item lg={12} md={12} xs={12}>
            <Box style={webStyle.billingContainer} className={classes.billingContainer}>
              <Box style={webStyle.billingRateTextContainer}>
                <Typography style={webStyle.billingRateText} className={classes.billingRateText} variant="h5">
                  {!this.state.isEdit ? t('boEmployees.newBillingRate') : t('boEmployees.editBillingRate')}
                </Typography>
                <CloseIcon
                  style={webStyle.cursorStyle}
                  onClick={this.handleCloseBillingRateDialog}
                />
              </Box>
              <Grid item lg={12} md={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xs={12} style={webStyle.gridItems}>
                    <Typography style={webStyle.contactForm} className={classes.contactForm} variant="body1" color="textPrimary" gutterBottom>
                      {t('boEmployees.name')}<span style={webStyle.redColor}>*</span>
                    </Typography>
                    <StyledTextField
                      data-test-id="form-data"
                      style={getConditionalStyle(this.state.isErrorBillingFormData.name)}
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={3}
                      name="name"
                      value={name}
                      onChange={this.handleChangeBillingFormData}
                      error={Boolean(this.state.isErrorBillingFormData.name)}
                    />
                    <Typography
                      variant="body1"
                      style={webStyle.errorMessage}
                      className={classes.contactForm}
                    >
                      {this.state.isErrorBillingFormData.name}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} style={webStyle.gridItem}>
                    <Typography style={webStyle.contactForm} className={classes.contactForm} variant="body1" color="textPrimary" gutterBottom>
                      {t('boEmployees.hourlyRate')}<span style={webStyle.redColor}>*</span>
                    </Typography>
                    <StyledTextField
                      data-test-id="form-data"
                      style={getConditionalStyle(this.state.isErrorBillingFormData.hourly_rate)}
                      fullWidth
                      variant="outlined"
                      name="hourly_rate"
                      value={hourly_rate}
                      onChange={this.handleChangeBillingFormData}
                      error={Boolean(this.state.isErrorBillingFormData.hourly_rate)}
                    />
                    <Typography
                      variant="body1"
                      style={webStyle.errorMessage}
                      className={classes.contactForm}
                    >
                      {this.state.isErrorBillingFormData.hourly_rate}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} style={webStyle.gridItem}>
                    <Typography style={webStyle.contactForm} className={classes.contactForm} variant="body1" color="textPrimary" gutterBottom>
                      {t('boEmployees.taxRate')}<span style={webStyle.redColor}>*</span>
                    </Typography>
                    <StyledTextField
                      data-test-id="form-data"
                      style={getConditionalStyle(this.state.isErrorBillingFormData.tax_rate)}
                      fullWidth
                      variant="outlined"
                      name="tax_rate"
                      value={tax_rate}
                      onChange={this.handleChangeBillingFormData}
                      error={Boolean(this.state.isErrorBillingFormData.tax_rate)}
                    />
                    <Typography
                      variant="body1"
                      style={webStyle.errorMessage}
                      className={classes.contactForm}
                    >
                      {this.state.isErrorBillingFormData.tax_rate}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box style={{...webStyle.billingRateTextContainer, paddingTop: "5rem"}} className={classes.billingRateTextContainer}>
                <ArcheiveButton
                  style={{ maxWidth: "156px" ,maxHeight:"56px"}}
                  className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
                  onClick={this.handleCloseBillingRateDialog}
                >
                  <Typography
                    style={webStyle.cancelButtonTextBillingRate}
                    className={classes.buttonTextMobile}
                    variant="subtitle2"
                  >
                    {t('boEmployees.cancel')}
                  </Typography>
                </ArcheiveButton>
                {!this.state.isEdit ? (
                  <ContainedButton
                    style={{ maxWidth: "156px" ,maxHeight:"56px"}}
                    className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
                    onClick={employee_id ? this.handleSaveAddNewBillingRate : this.handleSaveBillingRate}
                  >
                    <Typography
                      style={webStyle.buttonSaveUpdate}
                      className={classes.buttonTextMobile}
                      variant="subtitle2"
                    >
                      {t('boEmployees.save')}
                      <LoadingBackdrop
                        loading={this.state.createNewBillingRateLoading}
                      />
                    </Typography>
                  </ContainedButton>
                ) : (
                  <ContainedButton
                    style={{ maxWidth: "156px" ,minHeight:"56px"}}
                    className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
                    onClick={employee_id ? this.handleUpdateNewBillingRate : this.handleUpdateBillingRate}
                  >
                    <Typography
                      style={webStyle.buttonSaveUpdate}
                      className={classes.buttonTextMobile}
                      variant="subtitle2"
                    >
                      {t('boEmployees.update')}
                      <LoadingBackdrop
                        loading={this.state.updateNewBillingRateLoading}
                      />
                    </Typography>
                  </ContainedButton>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <LoadingBackdrop
          loading={this.state.showSingleBillingRatesLoading}
        />
      </StyledDialogBilling>
    );
  }

  staffPermissions = () => {
    const { classes }: any = this.props;
    return(
      <>
        {this.state.mockStaffPermissionData?.length > 0 && this.state.mockStaffPermissionData?.map((item: any) => (
          <Box key={item?.id}>
            <Box style={webStyle.staffPermissionContainer}>
              <Typography
                style={webStyle.staffTitle} component={"div"} className={classes.staffTitle}>
                {item?.title?.replace(/_/g, " ")}
              </Typography>
          
            </Box>
            {item?.fields?.map((i: any) => (
              <div style={webStyle.radioSelectContainer} className={item.id === 2 ? classes.radioSelectContainer : null} key={i?.id}>
                <StyledRadio
                  data-test-id="handleOptionChange"
                  color="primary"
                  name={item?.title}
                  value={i?.label}
                 
                  checked={this.state.selectedOptions?.find((option: any) => option?.category === item?.title.toLowerCase().replace(/ /g, "_"))?.action === i?.label}
                  onChange={(event) => this.handleOptionChange(event, item)}
                />
                <Typography style={{...webStyle.formData, ...webStyle.paddingBottom}} className={classes.permissionLabel} variant="body1" color="textPrimary">
                  {i?.label}
                </Typography>
              </div>
            ))}
            <span style={{...webStyle.hr, ...webStyle.permissionsHr}}></span>
          </Box>
        ))}
      </>
    );
  }

  deleteEmployeeDialog = (classes: ClassNameMap) => {
    const { t } = this.props;
    return (
      <StyledDialog
        open={this.state.deleteDialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Hidden smUp>
          <CloseIcon
            style={this.state.languageSelected === "ar" ? {...webStyle.closeIconDelete, left: "35px", right: "auto"} : webStyle.closeIconDelete}
            onClick={this.handleCloseDialog}
          />
        </Hidden>
        <StyledDialogTitle id="responsive-dialog-title" className={classes.deleteEmployeeLabel}>
          {t('boEmployees.deleteEmployee')}
        </StyledDialogTitle>
        <DialogContent style={webStyle.dialogContentStyle}>
          <Box style={webStyle.dialogContentTextStyle} className={classes.dialogContentTextStyle}>
            <Box>
              <StyledDialogContentText className={classes.deleteConfirmationLabel}>
                {t('boEmployees.deleteEmployeeTitle')}
              </StyledDialogContentText>
              <StyledDialogContentText className={`${classes.deleteConfirmationLabel} ${classes.dialogContent}`}>
                {`${this.state.showEmployeeData?.attributes?.full_name} ${t('boEmployees.deleteEmployeeDesc')}`}
              </StyledDialogContentText>
            </Box>
            <img
              style={webStyle.confirmationImage}
              className={classes.confirmationImage}
              src={confirmationImage}
              alt=""
            />
          </Box>
        </DialogContent>
        <StyledDialogActions className={classes.buttonsDialogActions}>
          <ArcheiveButton
            style={{ maxWidth: "156px" ,maxHeight:"56px"}}
            className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
            onClick={this.handleCloseDialog}
          >
            <Typography
              style={webStyle.cancelButtonTextBillingRate}
              className={`${classes.buttonTextMobile} ${classes.buttonText400}`}
              variant="subtitle2"
            >
              {t('boEmployees.cancel')}
            </Typography>
          </ArcheiveButton>
          <ContainedButton
            style={{ maxWidth: "156px" ,maxHeight:"56px"}}
            className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
            onClick={this.handleProceedDelete}
          >
            <Typography
              style={webStyle.buttonSaveUpdate}
              className={classes.buttonTextMobile}
              variant="subtitle2"
            >
              {t('boEmployees.delete')}
              <LoadingBackdrop
                loading={this.state.deleteEmployeeLoading}
              />
            </Typography>
          </ContainedButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  }

  renderImage = (item: any) => {
    let objectUrl = '';
  
    if (typeof window.URL.createObjectURL === 'function') {
      objectUrl = window.URL.createObjectURL(item);
    } else if (typeof window.webkitURL?.createObjectURL === 'function') {
      objectUrl = window.webkitURL.createObjectURL(item);
    } else {
      console.error('createObjectURL is not supported in this environment.');
    }
  
    return objectUrl;
  };
  
  previewImage = () => {
    const employee_id = localStorage.getItem(configJSON.employee_id);
    return (
      <>
        {!employee_id ?
          <img
            style={webStyle.userIcon}
            src={!this.state.employeeProfileUploaded ? emptyImage : this.renderImage(this.state.employeeProfile)}
          />
        :
          <img
            style={webStyle.userIcon}
            src={this.state.employeeProfileEditLocal ? this.renderImage(this.state.employeeProfileEditLocal) : (this.state.employeeProfileEdit || emptyImage)}
          />
        }
      </>
    );
  }

  billingRateModal = (classes: ClassNameMap) => {
    const employee_id = localStorage.getItem(configJSON.employee_id);
    const defaultBillingRate = (item: any) => {
      if(employee_id) {
        return item?.attributes?.name
      } else {
        return item?.name
      }
    }
    const { t } = this.props;
    return (
      <Box
        style={
          this.state.languageSelected === "ar" ?
          {...webStyle.billingRateModal, ...webStyle.positionStyle, ...webStyle.billingRateModalAr} :
          {...webStyle.billingRateModal, ...webStyle.positionStyle}
        }
      >
        {this.state.billingRateData?.map((item: any) => (
          <Typography
            key={item.id}
            style={{
              padding: this.state.languageSelected === "ar" ? "1rem 2.9rem 1rem 1rem" : "1rem 1rem 1rem 2.9rem",
              ...(defaultBillingRate(item) === this.state.defaultBillingRate ? {...webStyle.billingRate, ...webStyle.billingRateSelectedStyle} : webStyle.billingRate)
            }}
            
            variant="body1"
          >
            <Typography
              data-test-id="handleChangeBillingRate"
              style={webStyle.billingRateContainer}
              className={classes.formData}
              onClick={() => this.handleChangeBillingRate(item)}
              component={"div"}
            >
              {defaultBillingRate(item) === this.state.defaultBillingRate ?
                <img
                  style={webStyle.selectedIcon}
                  src={selectedIcon}
                  alt=""
                />
              : null}
              
              {defaultBillingRate(item)}
            </Typography>
            <span>
              {defaultBillingRate(item) !== this.state.defaultBillingRate &&
                <Box style={webStyle.editBillingRate}>
                  <Tooltip title={t('boEmployees.edit')} placement="bottom" arrow>
                    <img
                      data-test-id="handleClickOpenEditDialog"
                      style={webStyle.threeDotsIcon}
                      src={threeDotsIcon}
                      alt=""
                      onClick={() => this.handleClickOpenEditDialog(item)}
                    />
                  </Tooltip>
                  <Tooltip title={t('boEmployees.delete')} placement="bottom" arrow>
                    <CloseIcon
                      data-test-id="handleBillingRateDeleteDialogOpen"
                      style={webStyle.selectedIcon}
                      onClick={() => this.handleBillingRateDeleteDialogOpen(item)}
                    />
                  </Tooltip>
                </Box>
              }
            </span>
          </Typography>
        ))}
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { fullName, email, phone } = this.state.formData;
    
    const standardPayRate = this.state.billingRateData.filter((item: any) => item.active === true);

    const standardPayRateValue = () => {
    const employee_id = localStorage.getItem(configJSON.employee_id);
      if(employee_id) {
        return this.state.hourlyRate;
      } else {
        return standardPayRate[0]?.hourly_rate;
      }
    }

    const getDynamicStyle = (isHovered: boolean, backgroundColor: string) => {
      return isHovered ? {...webStyle.changeLogoInnerContainer, backgroundColor} : webStyle.changeLogoInnerContainer;
    }

    const employee_id = localStorage.getItem(configJSON.employee_id);

    const deleteButton = () => {
      return (
        <>
          {employee_id &&
            <ArcheiveButton
              style={{ maxWidth: "176px" }}
              className={`${classes.buttonBillingMobile} ${classes.deleteButtonMobile}`}
              onClick={this.handleClickOpenDialog}
            >
              <Typography
                style={webStyle.cancelButtonTextBillingRate}
                className={`${classes.buttonTextMobile} ${classes.buttonText400}`}
                variant="subtitle2"
              >
                {t('boEmployees.delete')}
              </Typography>
            </ArcheiveButton>
          }
        </>
      )
    }
    const { t } = this.props;
    return (
      <>
        <DashboardSideBar permissions={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.employee')} active={false} active1={2} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.boxContainerCreateEmp}
            className={classes.boxContainerCreateEmp}
          >
            <Container maxWidth={false}>
              <Box style={webStyle.backIconContainer}>
                <Hidden smUp>
                  <img
                    data-test-id="handleGoBackIcon"
                    style={mobileBackIcon(this.state.languageSelected)}
                    src={backIconMobile}
                    onClick={this.handleGoBackIcon}
                    alt=""
                  />
                </Hidden>
                <Typography style={webStyle.headerText} className={classes.headerText} variant="subtitle1">
                  {t('boEmployees.personalInformation')}
                </Typography>
              </Box>
              <Box style={webStyle.profileImageContainer}>
                <Box>
                  {this.previewImage()}
                  <img
                    data-test-id="handleClickEditIcon"
                    style={editIconFunction(this.state.languageSelected)}
                    src={editIcon}
                    alt="Image"
                    onClick={this.handleClickEditIcon}
                  />
                  {this.state.profileChangeModal ?
                    <ClickAwayListener onClickAway={this.handleClickEditIcon}>
                      <StyledBoxModal
                        style={styledBoxModal(this.state.languageSelected)}
                        languageSelected={this.state.languageSelected}
                      >
                        <img style={webStyle.dialogBoxIcon} src={dialogBoxIcon} alt="" />
                        <Box
                          data-test-id="handleMouse"
                          style={getDynamicStyle(this.state.isHovered, 'rgba(28,24,175,0.06)')}
                          className={classes.changeLogoInnerContainer}
                          component="label"
                          onMouseEnter={this.handleMouseEnterChangeLogo}
                          onMouseLeave={this.handleMouseLeaveChangeLogo}
                        >
                          <input
                            data-test-id="handleSelectFiles"
                            hidden
                            type="file"
                            accept="image/*"
                            onChange={this.handleClickUploadProfile}
                          />
                          <img style={webStyle.galleryIcon} src={galleryIcon} alt="" />
                          <Typography style={webStyle.changeLogoText} className={classes.formData} variant="body1">
                            {t('boEmployees.changeLogoImage')}
                          </Typography>
                        </Box>
                        <Box
                          data-test-id="handleDeleteUploadedLogo"
                          style={getDynamicStyle(this.state.isHovered2, 'rgba(28,24,175,0.06)')}
                          className={classes.changeLogoInnerContainer}
                          onClick={this.handleDeleteUploadedLogo}
                          onMouseEnter={this.handleMouseEnterDeleteLogo}
                          onMouseLeave={this.handleMouseLeaveDeleteLogo}
                        >
                          <img style={webStyle.galleryIcon} src={deleteProfileIcon} alt="" />
                          <Typography style={webStyle.removeImageText} className={classes.formData} variant="body1">
                            {t('boEmployees.removeImage')}
                          </Typography>
                        </Box>
                      </StyledBoxModal>
                    </ClickAwayListener>
                  : null}
                </Box>
              </Box>
              <Grid container spacing={2} className={classes.gridContainer} style={webStyle.gridContainer}>
                <Grid item lg={4} md={4} xs={12}>
                  <Typography style={webStyle.formData} className={classes.formData} variant="body1" color="textPrimary">
                    {t('boEmployees.fullName')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                  </Typography>
                  <StyledTextFields
                    data-test-id="formdata"
                    style={getConditionalStyle(this.state.isErrorFormData.fullName)}
                    fullWidth
                    variant="outlined"
                    name="fullName"
                    value={fullName}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.fullName)}
                  />
                  <Typography
                    variant="body1"
                    style={webStyle.errorMessage}
                    className={classes.formDataError}
                  >
                    {this.state.isErrorFormData.fullName}
                  </Typography>
                  <Box style={webStyle.checkBoxContainer}>
                    <StyledCheckbox
                      data-test-id="handleChangeEmailCheckBox"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      checked={this.state.emailAddressAsUsername}
                      onChange={this.handleChangeEmailCheckBox}
                    />
                    <Typography style={{...webStyle.formData, ...webStyle.paddingBottom}} className={classes.formData} variant="body1" color="textPrimary">
                      {t('boEmployees.useEmailAddress')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <Box style={webStyle.emailContainer}>
                    <Typography style={{...webStyle.formData, ...webStyle.paddingBottom}} className={classes.formData} variant="body1" color="textPrimary">
                      {t('boEmployees.emailAddress')}<span style={webStyle.redColor}>{configJSON.startSymbol}</span>
                    </Typography>
                    <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                      <img
                        style={webStyle.iIcon}
                        src={getSrcValue(this.state.showText, afterHoverIcon, iIcon)}
                        alt=""
                      />
                      {this.state.showText &&
                        <div
                          style={emailIconHover(this.state.languageSelected)}
                          className={this.state.languageSelected === "ar" ? classes.hoverStyleAr : classes.hoverStyle}
                        >
                          <Typography
                            style={webStyle.emailIconStyle}
                            variant="caption"
                          >
                            {t('boEmployees.emailIIconHoverLabel')}
                          </Typography>
                        </div>
                      }
                    </div>
                  </Box>
                  <StyledTextFields
                    data-test-id="formdata"
                    style={getConditionalStyle(this.state.isErrorFormData.email)}
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={email}
                    onChange={this.handleChangeFormData}
                    error={Boolean(this.state.isErrorFormData.email)}
                  />
                  <Typography
                    variant="body1"
                    style={webStyle.errorMessage}
                    className={classes.formDataError}
                  >
                    {this.state.isErrorFormData.email}
                  </Typography>
                  <Box style={webStyle.checkBoxContainer}>
                    <StyledCheckbox
                      data-test-id="handleChangeResendEmailInvitation"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      checked={this.state.resendEmailInvitation}
                      onChange={this.handleChangeResendEmailInvitation}
                    />
                    <Typography style={{...webStyle.formData, ...webStyle.paddingBottom}} className={classes.formData} variant="body1" color="textPrimary">
                      {t('boEmployees.resendEmailInvitation')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <Typography style={webStyle.formData} className={classes.formData} variant="body1" color="textPrimary">
                    {t('boEmployees.phoneNumber')}
                  </Typography>
                  <Grid container spacing={1} style={webStyle.paddingStyle}>
                    <Grid item lg={4} md={4} sm={3} xs={4}>
                      <Autocomplete
                        data-test-id="handleChangeCountryCode"
                        options={countryCodes}
                        classes={{option: classes.customOption}}
                        value={this.state.countryCode}
                        onChange={this.handleChangeCountryCode}
                        autoHighlight
                        renderInput={(params: any) => (
                          <StyledTextFieldAutoComplete
                            {...params}
                            fullWidth
                            variant="outlined"
                            languageSelected={this.state.languageSelected}
                          >
                          </StyledTextFieldAutoComplete>
                        )}
                      />
                    </Grid>
                    <Grid item lg={8} md={8} sm={9} xs={8}>
                      <StyledTextFields
                        data-test-id="formdata"
                        style={getConditionalStyle(this.state.isErrorFormData.phone)}
                        fullWidth
                        variant="outlined"
                        name="phone"
                        value={phone}
                        onChange={this.handleChangeFormData}
                        error={Boolean(this.state.isErrorFormData.phone)}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body1"
                    style={webStyle.errorMessage}
                    className={classes.formDataError}
                  >
                    {this.state.isErrorFormData.phone}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={`${classes.gridContainer} ${classes.billingRateGrid}`} style={webStyle.gridContainer}>
                <Grid item lg={4} md={4} xs={12} style={webStyle.positionRelative}>
                  <Typography style={webStyle.formData} className={classes.formData} variant="body1" color="textPrimary">
                    {t('boEmployees.defaultBillingRate')}
                  </Typography>
                  <Grid container>
                    <Grid item lg={9} md={9} xs={9}>
                      <Box
                        style={billingRateStyle(this.state.languageSelected)}
                        onClick={this.handleChangeDefaultBillingrateModalOpen}
                      >
                        <Typography style={webStyle.billingRateTextSelected} className={classes.formData} variant="body1">
                          {this.state.defaultBillingRate}
                        </Typography>
                        <img
                          style={webStyle.dropDownSelectIcon}
                          src={dropDownSelectIcon}
                          alt=""
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                      <Tooltip title={t('boEmployees.newBillingRate')} placement="top" arrow>
                        <Box
                          style={toolTipStyle(this.state.languageSelected)}
                          onClick={this.handleBillingRateDialogOpen}
                        >
                          <Typography component={"span"} variant="subtitle2" style={webStyle.plusIcon}>
                            {configJSON.plusIcon}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Grid>
                    {this.state.billingRateModal &&
                      <ClickAwayListener onClickAway={this.handleClickAway}>
                        {this.billingRateModal(classes)}
                      </ClickAwayListener>
                    }
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} xs={12} style={webStyle.paddingTopStyle}>
                  <Box style={webStyle.emailContainer}>
                    <Typography style={{...webStyle.formData, ...webStyle.paddingBottom}} className={classes.formData} variant="body1" color="textPrimary">
                      {t('boEmployees.standardPayRate')}
                    </Typography>
                    <div onMouseEnter={this.handleMouseEnterPayRate} onMouseLeave={this.handleMouseLeavePayRate}>
                      <img
                        style={webStyle.iIcon}
                        src={getSrcValue(this.state.showTextPayRate, afterHoverIcon, iIcon)}
                        alt=""
                      />
                      {this.state.showTextPayRate &&
                        <div
                          style={this.state.languageSelected === "ar" ?
                            {...webStyle.hoverStyle, ...webStyle.showPayRate, ...webStyle.transformHoverStyle} :
                            {...webStyle.hoverStyle, ...webStyle.showPayRate}
                          }
                          className={this.state.languageSelected === "ar" ? classes.hoverStyleAr : classes.hoverStyle}
                        >
                          <Typography
                            style={webStyle.standardPayStyle}
                            variant="caption"
                          >
                            {t('boEmployees.hoverlabelEmployeeCost')}
                          </Typography>
                        </div>
                      }
                    </div>
                  </Box>
                  <StyledTextFields
                    data-test-id="handleChangeStandardPayRate"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={standardPayRateValue()}
                  />
                </Grid>
              </Grid>
            </Container>
            {this.deleteBillingRatesDialog(classes)}
            {this.addNewBillingRatesDialog(classes)}
            <span style={webStyle.hr}></span>
            <Container maxWidth={false}>
              <Typography style={{...webStyle.headerText, ...webStyle.paddingBottomStyle}} className={classes.headerText} variant="subtitle1">
                {t('boEmployees.staffPermission')}
              </Typography>
              {this.staffPermissions()}
              <Box style={webStyle.buttonsContainer} className={classes.buttonsContainer}>
                <Box style={webStyle.cancelDeleteContainer}>
                  <>
                    <CancelButton
                     style={{ maxWidth: "176px" }}
                      className={`${classes.buttonMobile} ${classes.deleteButtonMobile}`}
                      onClick={this.handleEmployeeCancel}
                    >
                      <Typography
                        style={webStyle.textAlignCenter}
                        className={classes.buttonTextMobile}
                        variant="subtitle2"
                      >
                        {t('boEmployees.cancel')}
                      </Typography>
                    </CancelButton>

                    <Hidden xsDown>
                      {deleteButton()}
                    </Hidden>
                  </>
                </Box>
                {!employee_id ?
                  <ContainedButton
                    style={{ maxWidth: "176px" }}
                    className={`${classes.buttonMobile} ${classes.deleteButtonMobile}`}
                    onClick={this.handleSaveEmployeeData}
                  >
                    <Typography
                      style={{...webStyle.buttonTextSave, ...webStyle.textAlignCenter}}
                      className={classes.buttonTextMobile}
                      variant="subtitle2"
                    >
                      {t('boEmployees.save')}
                    </Typography>
                  </ContainedButton>
                :
                  <ContainedButton
                    style={{ maxWidth: "176px" }}
                    className={`${classes.buttonMobile} ${classes.deleteButtonMobile}`}
                    onClick={this.handleUpdateEmployeeData}
                  >
                    <Typography
                      style={{...webStyle.buttonTextSave, ...webStyle.textAlignCenter}}
                      className={classes.buttonTextMobile}
                      variant="subtitle2"
                    >
                      {t('boEmployees.update')}
                    </Typography>
                  </ContainedButton>
                }
              </Box>
              <Hidden smUp>
                {deleteButton()}
              </Hidden>
              {this.deleteEmployeeDialog(classes)}
            </Container>
          </Box>
        </StyledRoot>
        <LoadingBackdrop
          loading={
            (
              this.state.showEmployeeLoading ||
              this.state.createEmployeeLoading ||
              this.state.getEmployeeAllBillingRatesLoading ||
              this.state.updateEmployeeLoading ||
              this.state.retrieveAllPermissionsLoading
            )
          }
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerCreateEmp: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem 2rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8rem !important",
    },
  },
  gridContainer: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "0 !important",
    }
  },
  billingRateGrid: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.5rem !important",
    }
  },
  hoverStyle: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(-102%, -35%) !important",
    }
  },
  hoverStyleAr: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(102%, -35%) !important",
    }
  },
  staffTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    },
  },
  customOption: {
    color: CONSTANTS.color.midGrey
  },
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    }
  },
  formData: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  },
  permissionLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      lineHeight: "20px !important",
    }
  },
  radioSelectContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "5px 0 !important",
    }
  },
  buttonMobile: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "154px !important",
      minHeight: "48px !important"
    }
  },
  buttonBillingMobile: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "130px !important",
      minHeight: "47px !important"
    }
  },
  deleteButtonMobile: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
    }
  },
  buttonTextMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important",
    }
  },
  billingRateTextContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "2rem !important",
      gap: "1rem !important",
    }
  },
  changeLogoInnerContainer: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start !important",
    }
  },
  billingContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1.5rem !important"
    }
  },
  billingRateText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important"
    }
  },
  contactForm: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important"
    }
  },
  formDataError: {
    margin: "10px 0 !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    }
  },
  buttonText400: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: `${400} !important`,
    }
  },
  confirmationImage: {
    [theme.breakpoints.down("xs")]: {
      width: "110px !important",
      height: "109px !important"
    }
  },
  deleteEmployeeLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
      paddingBottom: "6px !important",
    }
  },
  deleteConfirmationLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
    }
  },
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem !important",
    }
  },
  dialogContentTextStyle: {
    [theme.breakpoints.down("xs")]: {
      gap: "0rem !important",
    }
  },
  buttonsDialogActions: {
    [theme.breakpoints.down("xs")]: {
      gap: "0.5rem !important",
    }
  },
  buttonsContainer: {
    [theme.breakpoints.down("xs")]: {
      gap: "1rem !important",
    }
  },
  closeIcon: {
    [theme.breakpoints.down("xs")]: {
      left: "91% !important",
      top: "13% !important",
    }
  },
  closeIconAr: {
    [theme.breakpoints.down("xs")]: {
      left: "auto !important",
      right: "82% !important",
      top: "13% !important",
    }
  },
  buttonCommon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: "200px",
    width: "100%",
    minHeight: "64px",
    borderRadius: "10px",
    textTransform: "none" as const,
  }
});

const webStyle = {
  boxContainerCreateEmp: {
    marginTop: "8rem",
    paddingBottom: "5rem"
  },
  headerText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  paddingBottomStyle: {
    paddingBottom: "1.5rem"
  },
  profileImageContainer: {
    marginTop: "1rem"
  },
  userIcon: {
    width: "80px",
    height: "80px",
    borderRadius: "50%"
  },
  editIcon: {
    width: "36px",
    height: "36px",
    transform: "translate(-83%, 8%)",
    cursor: "pointer"
  },
  transformStyleEditIcon: {
    transform: "translate(83%, 8%)"
  },
  changeLogoContainer: {
    padding: "0.5rem 0",
    position: "absolute" as const,
    maxWidth: "354px",
    width: "100%",
    minHeight: "125px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    transform: "translate(14%, 10%)",
    zIndex: 1,
  },
  transformStyleBoxModal: {
    transform: "translate(-14%, 10%)"
  },
  dialogBoxIcon: { 
    height: "8px",
    width: "16px",
    position: "absolute" as const,
    left: "8%",
    top: "-2%",
    transform: "translate(-50%, -50%)",
  },
  changeLogoInnerContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "1rem"
  },
  changeLogoText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  removeImageText: {
    color: CONSTANTS.color.crimsonRed,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  galleryIcon: {
    width: "24px",
    height: "24px",
  },
  gridContainer: {
    marginTop: "1.5rem",
    paddingRight: "2rem"
  },
  formData: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
    paddingBottom: "0.6rem"
  },
  paddingBottom: {
    paddingBottom: 0
  },
  positionRelative: {
    position: "relative" as const
  },
  paddingStyle: {
    paddingTop: '3px'
  },
  paddingTopStyle: {
    paddingTop: '5px'
  },
  checkBoxContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    paddingTop: "0.5rem"
  },
  emailContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "0.1rem"
  },
  iIcon: {
    width: "22px",
    height: "22px",
  },
  hoverStyle: {
    width: "100%",
    maxWidth: "308px",
    height: "80px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.lavenderBlue,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    transform: "translate(12%, -40%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99,
    cursor: "pointer",
  },
  showPayRate: {
    position: "absolute" as const,
    height: "60px"
  },
  transformHoverStyle: {
    transform: "translate(-12%, -40%)"
  },
  emailIconStyle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 1rem"
  },
  standardPayStyle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    padding: "0 1rem"
  },
  hr: {
    display: "flex",
    height: "2px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "2rem 0px 2rem 0"
  },
  permissionsHr: {
    margin: "1.5rem 0"
  },
  staffPermissionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "0.5rem"
  },
  staffTitle: {
    color: CONSTANTS.color.graphiteGrey,
    fontSize: "20px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textTransform:"capitalize" as const
  },
  radioButtonContainer: {
    width: "80px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: CONSTANTS.color.periwinkleBlue,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  radioButton: {
    width: "40px",
    height: "40px",
  },
  radioSelectContainer: {
    display: "flex",
    alignItems: "center",
  },
  radioSelect: {
    width: "26px",
    height: "26px",
  },
  dialogContentStyle: {
    padding: "0 24px 0 14px"
  },
  dialogContentTextStyle: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  confirmationImage: {
    width: "120px",
    height: "120px",
  },
  buttonsContainer: {
    padding: "1rem 0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelDeleteContainer: {
    display: 'flex',
    width: "100%",
    gap: "10px"
  },
  buttonContainerCancel: {
    border: `1px solid ${CONSTANTS.color.lightGrey}`,
  },
  cancelButtonStyle: {
    border: `1px solid ${CONSTANTS.color.brightRed}`,
    maxWidth: "156px",
    minHeight: "56px"
  },
  buttonSaveCommon: {
    backgroundColor: CONSTANTS.color.darkBlue,
    maxWidth: "156px",
    minHeight: "56px"
  },
  buttonTextCancel: {
    color: CONSTANTS.color.lightGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  buttonSaveUpdate: {
    textAlign: "center" as const,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    color: CONSTANTS.color.white
  },
  buttonCancel: {
    textAlign: "center" as const,
    color: CONSTANTS.color.brightRed
  },
  buttonCancelBillingRate: {
    maxWidth: "156px",
    minHeight: "56px",
    border: `1px solid ${CONSTANTS.color.crimsonRed}`
  },
  cancelButtonTextBillingRate: {
    textAlign: "center" as const,
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  deleteButtonBillingRate: {
    textAlign: "center" as const,
    color: CONSTANTS.color.darkBlue
  },
  buttonTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  errorMessage: {
    margin: "5px 0",
    color: CONSTANTS.color.red,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
  },
  plusIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0 10px 10px 0",
    border: `1px solid ${CONSTANTS.color.lightBeigeGrey}`,
    minHeight: "58px",
    cursor: "pointer"
  },
  plusIcon: {
    opacity: 0.8,
  },
  billingContainer: {
    padding: "3rem"
  },
  billingRateError: {
    backgroundColor: CONSTANTS.color.lightRed,
    borderRadius: "10px"
  },
  billingRateTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  billingRateText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  contactForm: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginBottom: "0.8rem",
    lineHeight: "16px"
  },
  gridItems: {
    marginTop: '1.5rem'
  },
  cursorStyle: {
    cursor: "pointer",
    color: CONSTANTS.color.black
  },
  gridItem: {
    marginTop: '0.5rem'
  },
  defaultBillingRateContainer: {
    border: `1px solid ${CONSTANTS.color.paleBlueGrey}`,
    height: "58px",
    borderRadius: "10px 0 0 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem",
    cursor: "pointer",
  },
  borderRadiusBillingAr: {
    borderRadius: "0px 10px 10px 0px"
  },
  billingRateTextSelected: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  billingRateModal: {
    width: "73.5%",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    left: "8px",
    top: "92%",
    zIndex: 1,
  },
  billingRateModalAr: {
    transform: "translate(0%, 60px)",
    top: "auto",
    left: "auto"
  },
  positionStyle: {
    position: "absolute" as const
  },
  selectedIcon: {
    height: "26px",
    width: "26px",
  },
  dropDownSelectIcon: {
    height: "7px",
    width: "12px",
  },
  threeDotsIcon: {
    width: "24px",
    height: "24px",
  },
  editBillingRate: {
    display: "flex",
    gap: "1rem"
  },
  billingRate: {
    cursor: "pointer",
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    gap: "5px",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    justifyContent: "space-between",
  },
  billingRateSelectedStyle: {
    color: CONSTANTS.color.darkBlue,
    backgroundColor: "rgba(28,24,175,0.06)",
    padding: "1.1rem 1rem"
  },
  billingRateContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },
  closeIcon: {
    position: "absolute" as const,
    left: "93%",
    top: "20%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
    color: CONSTANTS.color.black
  },
  closeIconDelete: {
    position: "absolute" as const,
    right: "10px",
    top: "35px",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
    color: CONSTANTS.color.lightBlack
  },
  redColor: {
    color: CONSTANTS.color.red,
  },
  gapStyle: {
    gap: "10px"
  },
  backIconMobile: {
    height: "24px",
    width: "12px",
  },
  transformStyle: {
    transform: "rotate(180deg)"
  },
  backIconContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  textAlignCenter: {
    textAlign: "center" as const
  }
}

const style: any = withStyles(styles)(CreateEmployee);

const router = withRouter(style);
export default withTranslation()(withSnackbar(router));
// Customizable Area End